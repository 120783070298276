import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import { useLazyQuery } from '@apollo/react-hooks'
import { UserContext } from '../contexts/user'
import getMe from '../api/me.graphql'
import { setToken } from '../api/token'

import Layout from '../components/layout'
import SEO from '../components/seo'

import './index.css'

const LoginPage = () => {
  const { user, setUser } = useContext(UserContext)
  const [signingIn, setSigningIn] = useState(false)
  const [fetchUser, { error }] = useLazyQuery(getMe, {
    onCompleted: (data) => {
      setUser(data.getMe)
    },
    onError: () => {
      setSigningIn(false)
    },
  })

  const onSignedIn = (event: MessageEvent) => {
    if (
      event.data &&
      event.data.type === 'signin-complete' &&
      typeof event.data.token === 'string'
    ) {
      setToken(event.data.token)
      fetchUser()
    }
  }

  useEffect(() => {
    if (user) {
      navigate('/dashboard', { replace: true })
    }
  }, [user])

  const onGitHubSignIn = () => {
    window.addEventListener('message', onSignedIn)
    window.open(
      `https://github.com/login/oauth/authorize?scope=user:email&client_id=${process.env.GATSBY_GITHUB_CLIENT_ID}`
    )
    setSigningIn(true)
  }

  const onGoogleSignIn = () => {
    window.addEventListener('message', onSignedIn)
    window.open(
      `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.GATSBY_GOOGLE_CLIENT_ID}&response_type=code&scope=openid%20email%20profile&redirect_uri=${process.env.GATSBY_API_URL}/oauth/google&access_type=offline`
    )
    setSigningIn(true)
  }
  return (
    <Layout>
      <SEO title="Lona" />
      {signingIn ? (
        'Signing in...'
      ) : (
        <div>
          {error ? <p>{error.message}</p> : null}
          <button onClick={onGitHubSignIn}>Login with GitHub</button>
          <button onClick={onGoogleSignIn}>Login with Google</button>
        </div>
      )}
    </Layout>
  )
}

export default LoginPage
